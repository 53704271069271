<template>
    <v-container>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="documents"
            :search="search" 
            item-key="id"
            class="elevation-1"
            :loading="loading"
            loading-text="cargando informacion..."
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Documentos</v-toolbar-title>
          <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search" class="mr-3"
                    append-icon="mdi-magnify"
                    label="Buscar documento"
                    single-line
                    hide-details
                  ></v-text-field>
                <v-dialog
                  v-model="dialog"
                  persistent
                  max-width="500px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="success"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      DOCUMENTO
                      <v-icon right dark>
                        mdi-cloud-upload
                    </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                  <v-row style="margin: 0;"
                  > 
                    <v-col class="col-12"><v-card 
                  >
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span> 
                    </v-card-title>
  
                    <v-card-text>
                      <v-container> 
                        <v-row> 
                          
                          <v-col
                            cols="12"
                            sm="12"
                          > 
                          <v-file-input
                            v-model="files"
                            truncate-length="1"
                            label="Selecciona el documento"
                            show-size
                            accept="application/pdf, image/png, image/jpeg, image/jpg, video/mp4"
                            prepend-icon="mdi-file-upload"
                            >
                        </v-file-input>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                          > <v-text-field
                              v-model="nombreDocumento"
                              label="Nombre para el documento"   
                              @keydown="nombreDocumentoEdit"
                              @change="nombreDocumentoEdit"
                              required
                            ></v-text-field>
                          </v-col>

                        </v-row>
                         
                      </v-container>
                    </v-card-text>
  
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="guardarDocumento"
                        :loading="isLoading"
                      >
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card></v-col> 
                  </v-row>
                </v-card>
                </v-dialog> 
              </v-toolbar>
            </template>
  
            <template v-slot:item.actions="{ item }"> 
              <v-icon
                small 
                @click="copyLink(item)" 
              >
                mdi-content-copy
              </v-icon>
              <v-divider
                  class="mx-2"
                  inset
                  vertical
                ></v-divider>
              <v-icon
                small
                @click="edit(item)" 
              >
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:no-data>
              <!-- <v-btn
                color="primary"
                @click="initialize"
              >
                Reiniciar
              </v-btn> -->
            </template>
          </v-data-table>
        </v-col>
      </v-row> 
    <v-snackbar
        :color="alertaModalsColor"
        v-model="alertaModals"
        :multi-line="true"
        :timeout="5000"
        centered
        outlined
        
      >
        {{ textAlertModals }}
  
        <template   >
          <v-btn 
          :color="alertaModalsColor"
            text  
            elevation="24"
             @click="alertaModals = false"
          >
            OK
          </v-btn>
        </template>
      </v-snackbar>
  
    </v-container>
   
  </template>
  <script>
  import ApiService from '../../service/apis'
  import servicios from '../../service/login'
  //import moment from 'moment'    
  export default {
    name:'Documents',
    data: () => ({
        idDocument : 0,
        files:null,
        disableBtnAddHsm : true,
        nombreDocumento : '' ,
        dialog: false,
        dialogDelete: false,
        selected: [{}],
        isLoading:false,
        isLoadingDeleteBtn : false,
        sesion:JSON.parse(sessionStorage.getItem('sesion')),
        search:null,
        textAlertModals:'',
        alertaPaso1:false, 
        alertaTypo : 'success', 
        typeMedia : '',
        modelTipo:null,
        alertaModalsColor : '',
        alertaModals : false,  
        documents :[],
        headers: [
        { text: 'Nombre documento', value: 'nombre_generado' },  
        { text: 'Url', align: 'start', value: 'url' },
        { text: 'Formato', value: 'tipo' },
        { text: 'Fecha Registro', value: 'createdAt' }, 
        { text: 'Acciones', value: 'actions', sortable: false },
        ],
        blacklists: [],
        editedIndex: -1,
        show1:false,
        editedItem: {
          id: 0,
          status: 1,
          senderid: '',
          comentario:''
        },
        defaultItem: {
          id: 0,
          status: 1,
          senderid: '',
          comentario:''
        },
        loading:false
      }),
      computed: {
        formTitle () {
          return this.editedIndex === -1 ? 'Nuevo documento'  : 'Documentos' 
        },
      },
      watch: {
        dialog (val) {
          val || this.close()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },
      },
      created () {
        //this.seleccionAsistente()
        this.getDocumentos()
      },
      updated(){
        this.evento()
      },
      methods: {
        evento(){
          this.$emit('Hsm', this.selected)
        },
        initialize () {
            this.getDocumentos()
        },
        close () {
        this.files=null
        this.dialog = false
        this.nombreDocumento = ''
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      getDocumentos(){
        this.loading=true 
        ApiService.getDocumentos().then(res =>{

            
            res.lista.forEach(element => {
                element.url = 'https://sfo3.digitaloceanspaces.com/sd-ftp/ENVIOS/documentos/'+ element.nombre_generado
             })
            if(res){
                this.loading=false   
                this.documents = res.lista
            }
        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1 
        })
      },
      copyLink(item){
        this.mensajeAlertaModals('success','Elemento en portapapeles..')
         navigator.clipboard.writeText(item.url)

      },
      edit(item){
        this.dialog = true
        this.idDocument = item.id
        this.nombreDocumento = item.nombre_generado.split('.')[0]
      },
      nombreDocumentoEdit(){
        //console.log(this.nombreDocumento)
        this.nombreDocumento = this.nombreDocumento.replace(/[^-_a-zA-Z0-9]/g,'').trim() 
      },
      guardarDocumento() {    
        this.isLoading = true 
        let formData = new FormData()
        this.nombreDocumento = this.nombreDocumento.replace(/[^-_a-zA-Z0-9]/g,',').trim() 
        formData.append("id",this.idDocument)
        formData.append("upload", this.files)
        formData.append("capturista_id",this.sesion.id)  
        formData.append("nombre_generado",this.nombreDocumento)

      if(this.files!=null){
        if(this.nombreDocumento.trim().length<=0){
            this.mensajeAlertaModals('error','Espesificar nombre para el documento.')
            this.isLoading = false 
        }else{ 
            servicios.subirDocumento(formData,this.nombreDocumento).then(res =>{
            if(res){
                this.mensajeAlertaModals('success','Se cargo correctamente el documento.')
                this.isLoading = false  
                this.idDocument = 0
                this.close()
                this.getDocumentos()
            }
            })
        }
      }

        
        
      }, 
      mensajeAlertaModals(tipo,texto){
        this.alertaModalsColor =tipo
        this.alertaModals =true
        this.textAlertModals =texto
      },
      },
  }
  </script>
  <style>
  .v-application .headline {
    letter-spacing: unset;
  }
  </style>